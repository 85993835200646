import "./App.css";

import { Authenticator } from "@aws-amplify/ui-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Root } from "./pages/Root";

import { Login } from "./pages/Login";
import { RequireAuth } from "./components/RequireAuth";
import ChangeHorse from "./pages/ChangeHorse";
import { DataProvider } from "./context/DataProvider";
import { DateAndPageProvider } from "./context/DateProvider";
import Home from "./pages/Home";
import HorseIn from "./pages/HorseIn";
import HorseNote from "./pages/ChangeHorseNote";
import LessonNote from "./pages/ChangeLessonNote";
import ChangeHorseSaddle from "./pages/ChangeHorseSaddle";
import AddRider from "./pages/AddRider";
import { WebsocketProvider } from "./context/WebsocketProvider";
import RiderAbsent from "./pages/RiderAbsent";

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <div>Not Found</div>,
    children: [
      {
        path: "/",
        element: (
          <RequireAuth>
            <Home />
          </RequireAuth>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/change/horse",
        element: (
          <RequireAuth>
            <ChangeHorse />
          </RequireAuth>
        ),
      },
      {
        path: "/change/saddle",
        element: (
          <RequireAuth>
            <ChangeHorseSaddle />
          </RequireAuth>
        ),
      },
      {
        path: "/change/in",
        element: (
          <RequireAuth>
            <HorseIn />
          </RequireAuth>
        ),
      },
      {
        path: "/change/note",
        element: (
          <RequireAuth>
            <HorseNote />
          </RequireAuth>
        ),
      },
      {
        path: "/change/lessonNote",
        element: (
          <RequireAuth>
            <LessonNote />
          </RequireAuth>
        ),
      },
      {
        path: "/add/rider",
        element: (
          <RequireAuth>
            <AddRider />
          </RequireAuth>
        ),
      },
      {
        path: "/change/absences",
        element: (
          <RequireAuth>
            <RiderAbsent />
          </RequireAuth>
        ),
      },
    ],
  },
]);

// A comment
function App() {
  return (
    <Authenticator.Provider>
      <WebsocketProvider>
        <DateAndPageProvider>
          <DataProvider>
            <RouterProvider router={router} />
          </DataProvider>
        </DateAndPageProvider>
      </WebsocketProvider>
    </Authenticator.Provider>
  );
}

export default App;
