import HorseSelect from "../components/HorseSelect";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatTime } from "../helpers/utils";
import { SubmitButton } from "../components/SubmitButton";
import dayjs from "dayjs";

import { Grid, Typography, Box } from "@mui/material";
import { HttpClient } from "../helpers/httpClient";
import { Horse, HorseSaddle } from "../types/Horses";
import KnowledgeableSelect from "../components/KnowledgeableSelect";
import { Day } from "../types/Board";
import { useDataContext } from "../context/DataProvider";
import { useWebsocketContext } from "../context/WebsocketProvider";
import { publish, publishAndCall } from "../websockets/Websockets";

const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function ChangeHorseSaddle() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading2, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [day, setDay] = useState<Day>();
  // const [date, setDate] = useState<dayjs.Dayjs>(dayjs());
  const { boardData, horseData, horseSaddleData, loading, fetchData } =
    useDataContext();
  const { client, loadingWeb } = useWebsocketContext();
  console.log(boardData, horseData, loading);

  async function handleSaddleChange(
    selectedOption: HorseSaddle | undefined,
    riderId: string,
    lessonId: string,
    horseId: string
  ) {
    try {
      const result = await publishAndCall(
        client,
        {
          type: "horseSaddleChange",
          data: {
            lesson: lessonId,
            rider: riderId,
            saddle: selectedOption?.saddle,
            pads: selectedOption?.pads,
            additions: selectedOption?.additions,
          },
        },
        {
          path: `/horses/${horseId}/saddles/lessons/${lessonId}/riders/${riderId}/date/${
            location.state.date ?? dayjs().format("YYYY-MM-DD")
          }`,
          body: {
            id: selectedOption?.id,
            saddleId: selectedOption?.saddleId,
          },
        },
        true
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!boardData) {
    return <div>Error: Data not available</div>;
  }

  return (
    <div>
      <Grid item>
        <Typography variant="h3" gutterBottom align="center">
          Change Saddles
        </Typography>
      </Grid>
      <Box padding={"2em"}>
        {boardData
          ? boardData.map((lessonTime, index) => (
              <Box key={index}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    paddingTop: "1em",
                  }}
                >
                  {formatTime(lessonTime.lessonTime)}
                </Typography>

                <Grid
                  container
                  item
                  columnSpacing={2}
                  paddingTop="0.5em"
                  paddingBottom="0.5em"
                  marginLeft="-0.5em"
                >
                  <Grid item xs={7} sx={{ fontWeight: "bold" }}>
                    Saddle
                  </Grid>
                  <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                    Horse
                  </Grid>
                  <Grid item xs={3} sx={{ fontWeight: "bold" }}>
                    Rider
                  </Grid>
                </Grid>

                {lessonTime.entries.map((rider, index) => {
                  if (!rider.horseId) {
                    return null;
                  }

                  return (
                    <Grid
                      item
                      container
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                      }}
                      alignItems="center"
                      justifyContent="center"
                      columnSpacing={2}
                      paddingTop="1em"
                      paddingBottom="1em"
                      marginLeft="-0.5em"
                    >
                      <Grid item xs={7}>
                        <KnowledgeableSelect
                          label="Saddle"
                          options={
                            horseSaddleData &&
                            horseSaddleData[rider.horseId] &&
                            horseSaddleData[rider.horseId].length > 0
                              ? horseSaddleData[rider.horseId]?.map(
                                  (saddle) => {
                                    return {
                                      display: saddle.saddle,
                                      otherDetails: saddle,
                                    };
                                  }
                                )
                              : []
                          }
                          initialSelected={
                            horseSaddleData &&
                            horseSaddleData[rider.horseId] &&
                            horseSaddleData[rider.horseId].length > 0 &&
                            rider.saddle.saddle
                              ? horseSaddleData[rider.horseId]?.findIndex(
                                  (obj) => obj.saddle === rider.saddle.saddle
                                )!
                              : -1
                          }
                          defaultOption={{
                            id: "",
                            horse: "",
                            saddle: "",
                            saddleId: "",
                            padsArray: [],
                            pads: "",
                            additions: "",
                            notes: "",
                            rank: -1,
                            priorityFit: false,
                            smallSaddle: false,
                          }}
                          onChange={(
                            selectedOption: HorseSaddle | undefined
                          ) => {
                            handleSaddleChange(
                              selectedOption,
                              rider.personId,
                              lessonTime.lessonId.toString(),
                              rider.horseId
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        {rider.horseName}
                      </Grid>
                      <Grid item xs={3}>
                        {rider.personName}
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            ))
          : null}
      </Box>
      <SubmitButton
        handleSubmit={async () => {
          navigate("/");
          await fetchData();
        }}
        disabled={loading}
        label="Back"
      />
    </div>
  );
}

export default ChangeHorseSaddle;
