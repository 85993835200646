import { Box, TextField, TextareaAutosize } from "@mui/material";
import { ChangeEventHandler, FocusEventHandler } from "react";

function LimitedTextArea(props: {
  maxChars: number;
  label: string;
  data: string;
  setData: ChangeEventHandler;
  onLeaveTextBox?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}) {
  const maxChars = props.maxChars;

  return (
    <>
      <Box>
        <TextField
          multiline
          minRows={3}
          onChange={props.setData}
          value={props.data}
          style={{ width: "100%" }}
          onBlur={props.onLeaveTextBox ? props.onLeaveTextBox : () => {}}
        />
      </Box>
      <Box>
        <div
          style={{
            textAlign: "right",
            color: props.data && props.data.length > maxChars ? "red" : "grey",
          }}
        >
          {props.data ? props.data.length : 0}/{maxChars}
        </div>
      </Box>
    </>
  );
}

export default LimitedTextArea;
