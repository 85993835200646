import HorseSelect from "../components/HorseSelect";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatTime } from "../helpers/utils";
import { SubmitButton } from "../components/SubmitButton";
import dayjs from "dayjs";

import { Grid, Typography, Box } from "@mui/material";
import { HttpClient } from "../helpers/httpClient";
import { Horse } from "../types/Horses";
import KnowledgeableSelect from "../components/KnowledgeableSelect";
import { Day } from "../types/Board";
import { useDataContext } from "../context/DataProvider";
import { useWebsocketContext } from "../context/WebsocketProvider";
import { publish, publishAndCall } from "../websockets/Websockets";
import { useDateAndPageContext } from "../context/DateProvider";

const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function ChangeHorse() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading2, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [day, setDay] = useState<Day>();
  // const [date, setDate] = useState<dayjs.Dayjs>(dayjs());
  // const [horses, setHorses] = useState<Horse[]>([]);
  const { boardData, horseData, loading, fetchData } = useDataContext();
  const { client } = useWebsocketContext();
  const { date } = useDateAndPageContext();
  console.log(boardData, horseData, loading);

  async function handleHorseChange(
    selectedOption: Horse | undefined,
    riderId: string,
    lessonId: string
  ) {
    try {
      const result = await publishAndCall(
        client,
        {
          type: "horseChange",
          data: {
            lesson: lessonId,
            rider: riderId,
            horseId: selectedOption?.id,
            horseName: selectedOption?.barnName,
          },
        },
        {
          path: `/riders/${riderId}/lessons/${lessonId}/date/${
            location?.state?.date ?? dayjs().format("YYYY-MM-DD")
          }/horse`,
          body: {
            horseId: selectedOption?.id,
            horseName: selectedOption?.barnName,
          },
        },
        true
      );
      console.log(result);
    } catch (error) {
      console.log(error);
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!boardData) {
    return <div>Error: Data not available</div>;
  }

  return (
    <div>
      <Grid item>
        <Typography variant="h3" gutterBottom align="center">
          Change Horses
        </Typography>
      </Grid>
      <Box padding={"2em"}>
        {boardData
          ? boardData.map((lessonTime, index) => (
              <Box key={index}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    paddingTop: "1em",
                  }}
                >
                  {formatTime(lessonTime.lessonTime)}
                </Typography>

                <Grid
                  container
                  item
                  columnSpacing={2}
                  paddingTop="0.5em"
                  paddingBottom="0.5em"
                  marginLeft="-0.5em"
                >
                  <Grid item xs={5} sx={{ fontWeight: "bold" }}>
                    Name
                  </Grid>
                  <Grid item xs={7} sx={{ fontWeight: "bold" }}>
                    Horse
                  </Grid>
                </Grid>

                {lessonTime.entries.map((rider, index) => {
                  return (
                    <Grid
                      item
                      container
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                      }}
                      alignItems="center"
                      justifyContent="center"
                      columnSpacing={2}
                      paddingTop="1em"
                      paddingBottom="1em"
                      marginLeft="-0.5em"
                    >
                      <Grid item xs={5}>
                        {rider.personName}
                      </Grid>
                      <Grid item xs={7}>
                        <KnowledgeableSelect
                          label="Horse"
                          options={
                            horseData?.map((horse) => {
                              return {
                                display: horse.barnName,
                                otherDetails: horse,
                              };
                            })!
                          }
                          initialSelected={
                            rider.horseId
                              ? horseData?.findIndex(
                                  (obj) => obj.id === rider.horseId
                                )!
                              : -1
                          }
                          defaultOption={{
                            id: "",
                            barnName: "",
                            birthDate: "",
                            owner: "",
                            type: "",
                            hasPhoto: false,
                            dayOff: "",
                          }}
                          onChange={(selectedOption: Horse | undefined) => {
                            handleHorseChange(
                              selectedOption,
                              rider.personId,
                              lessonTime.lessonId.toString()
                            );
                          }}
                          itemTextColour={(horse: Horse) => {
                            if (
                              date &&
                              horse.dayOff === dayString[date.day()]
                            ) {
                              return "red";
                            }
                            return "black";
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            ))
          : null}
      </Box>
      <SubmitButton
        handleSubmit={async () => {
          navigate("/");
          await fetchData();
        }}
        disabled={loading}
        label="Back"
      />
    </div>
  );
}

export default ChangeHorse;
