import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatTime } from "../helpers/utils";
import { SubmitButton } from "../components/SubmitButton";

import { Grid, Typography, Box, Checkbox } from "@mui/material";
import { HttpClient } from "../helpers/httpClient";
import { useDataContext } from "../context/DataProvider";
import { Day } from "../types/Board";
import { publish, publishAndCall } from "../websockets/Websockets";
import { useWebsocketContext } from "../context/WebsocketProvider";
import { useDateAndPageContext } from "../context/DateProvider";

const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function RiderAbsent() {
  const navigate = useNavigate();
  const [loading2, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { boardData, horseData, loading, fetchData } = useDataContext();
  const { date } = useDateAndPageContext();
  const { client, loadingWeb } = useWebsocketContext();

  const [data, setData] = useState<{ [key: string]: boolean }>({});

  async function handleRiderAbsentChange(
    riderAbsent: boolean,
    riderId: string,
    lessonId: string
  ) {
    console.log(riderAbsent);
    let request: {
      path: string;
      body: object | [];
    } = {
      path: "/riders/absences",
      body: [
        {
          rider: riderId,
          absentDate: date.format("YYYY-MM-DD"),
          lesson: Number(lessonId),
        },
      ],
    };
    if (!riderAbsent) {
      request = {
        path: `/riders/${riderId}/absences`,
        body: {
          absentDate: date.format("YYYY-MM-DD"),
          lesson: Number(lessonId),
        },
      };
    }

    try {
      const result = publishAndCall(
        client,
        {
          type: "riderAbsentChange",
          data: {
            lesson: lessonId,
            rider: riderId,
            riderAbsent,
          },
        },
        request,
        !riderAbsent
      );
      console.log(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!boardData) {
    return <div>Error: Data not available</div>;
  }

  return (
    <div>
      <Grid item>
        <Typography variant="h3" gutterBottom align="center">
          Mark Rider Absent
        </Typography>
      </Grid>
      <Box padding={"2em"}>
        {boardData?.map((lessonTime, index1) => (
          <Box key={index1}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                paddingTop: "1em",
              }}
            >
              {formatTime(lessonTime.lessonTime)}
            </Typography>

            <Grid
              container
              item
              columnSpacing={2}
              paddingTop="0.5em"
              paddingBottom="0.5em"
              marginLeft="-0.5em"
            >
              <Grid item xs={5} sx={{ fontWeight: "bold" }}>
                Rider
              </Grid>
              <Grid item xs={7} sx={{ fontWeight: "bold" }}>
                Rider will be absent
              </Grid>
            </Grid>

            {lessonTime.entries.map((entry, index2) => {
              console.log(
                data[entry.personId + " " + lessonTime.lessonId.toString()],
                entry.entryModifications,
                entry
              );
              if (!entry.personName) {
                return null;
              }

              return (
                <Grid
                  item
                  container
                  key={index2}
                  sx={{
                    backgroundColor: index2 % 2 === 0 ? "#f5f5f5" : "white",
                  }}
                  alignItems="center"
                  justifyContent="center"
                  columnSpacing={2}
                  paddingTop="1em"
                  paddingBottom="1em"
                  marginLeft="-0.5em"
                >
                  <Grid item xs={5}>
                    {entry.personName}
                  </Grid>
                  <Grid item xs={7}>
                    <Checkbox
                      checked={
                        data[
                          entry.personId + " " + lessonTime.lessonId.toString()
                        ] !== undefined
                          ? data[
                              entry.personId +
                                " " +
                                lessonTime.lessonId.toString()
                            ]
                          : entry.entryModifications == "riderAbsent"
                      }
                      onChange={(event: any) => {
                        let newData = {
                          ...data,
                        };
                        newData[
                          entry.personId + " " + lessonTime.lessonId.toString()
                        ] = event.target.checked;
                        setData(newData);

                        handleRiderAbsentChange(
                          event.target.checked,
                          entry.personId,
                          lessonTime.lessonId.toString()
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        ))}
      </Box>
      <SubmitButton
        handleSubmit={async () => {
          navigate("/");
          await fetchData();
        }}
        disabled={loading2}
        label="Back"
      />
    </div>
  );
}

export default RiderAbsent;
