import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatTime } from "../helpers/utils";
import { SubmitButton } from "../components/SubmitButton";

import { Grid, Typography, Box, Checkbox } from "@mui/material";
import { HttpClient } from "../helpers/httpClient";
import { useDataContext } from "../context/DataProvider";
import LimitedTextArea from "../components/LimitedTextArea";
import { useWebsocketContext } from "../context/WebsocketProvider";
import { publish, publishAndCall } from "../websockets/Websockets";

const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function HorseNote() {
  const navigate = useNavigate();
  const [loading2, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { boardData, horseData, loading, fetchData } = useDataContext();
  const { client, loadingWeb } = useWebsocketContext();

  const [data, setData] = useState<{ [key: string]: string }>({});

  async function handleNoteChange(
    notes: string,
    riderId: string,
    lessonId: string
  ) {
    try {
      const result = publishAndCall(
        client,
        {
          type: "horseNoteChange",
          data: {
            lesson: lessonId,
            rider: riderId,
            notes,
          },
        },
        {
          path: `/riders/${riderId}/lessons/${lessonId}/notes`,
          body: {
            notes,
          },
        },
        true
      );

      console.log(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!boardData) {
    return <div>Error: Data not available</div>;
  }

  return (
    <div>
      <Grid item>
        <Typography variant="h3" gutterBottom align="center">
          Change Horse/Rider Note
        </Typography>
      </Grid>
      <Box padding={"2em"}>
        {boardData?.map((lessonTime, index) => (
          <Box key={index}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                paddingTop: "1em",
              }}
            >
              {formatTime(lessonTime.lessonTime)}
            </Typography>

            <Grid
              container
              item
              columnSpacing={2}
              paddingTop="0.5em"
              paddingBottom="0.5em"
              marginLeft="-0.5em"
            >
              <Grid item xs={3} sx={{ fontWeight: "bold" }}>
                Rider
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: "bold" }}>
                Horse
              </Grid>
              <Grid item xs={6} sx={{ fontWeight: "bold" }}>
                Note
              </Grid>
            </Grid>

            {lessonTime.entries.map((entry, index) => {
              return (
                <Grid
                  item
                  container
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                  }}
                  alignItems="center"
                  justifyContent="center"
                  columnSpacing={2}
                  paddingTop="1em"
                  paddingBottom="1em"
                  marginLeft="-0.5em"
                >
                  <Grid item xs={3}>
                    {entry.personName}
                  </Grid>
                  <Grid item xs={3}>
                    {entry.horseName ?? ""}
                  </Grid>
                  <Grid item xs={6}>
                    <LimitedTextArea
                      label="Note"
                      maxChars={250}
                      data={
                        data[
                          entry.personId + " " + lessonTime.lessonId.toString()
                        ] !== undefined
                          ? data[
                              entry.personId +
                                " " +
                                lessonTime.lessonId.toString()
                            ]
                          : entry.notes
                      }
                      setData={(event: ChangeEvent<HTMLInputElement>) => {
                        let newData = {
                          ...data,
                        };
                        newData[
                          entry.personId + " " + lessonTime.lessonId.toString()
                        ] = event.target.value;
                        setData(newData);
                      }}
                      onLeaveTextBox={() => {
                        if (
                          data[
                            entry.personId +
                              " " +
                              lessonTime.lessonId.toString()
                          ] !== undefined
                        ) {
                          handleNoteChange(
                            data[
                              entry.personId +
                                " " +
                                lessonTime.lessonId.toString()
                            ],
                            entry.personId,
                            lessonTime.lessonId.toString()
                          );
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        ))}
      </Box>
      <SubmitButton
        handleSubmit={async () => {
          navigate("/");
          await fetchData();
        }}
        disabled={loading2}
        label="Back"
      />
    </div>
  );
}

export default HorseNote;
