import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";
import { HttpClient } from "../helpers/httpClient";

interface DateContextType {
  date: dayjs.Dayjs;
  setDate: Dispatch<SetStateAction<dayjs.Dayjs>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const DateContext = createContext<DateContextType | undefined>(undefined);

export const useDateAndPageContext = () => {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error("useDateContext must be used within a DateProvider");
  }
  return context;
};

export function DateAndPageProvider(props: { children: ReactNode }) {
  const [date, setDate] = useState<dayjs.Dayjs>(dayjs());
  const [page, setPage] = useState<number>(1);

  const getDateAndPage = async () => {
    try {
      const result: {
        boardDate: { Time: string; Valid: boolean };
        page: number;
      } = await HttpClient.get("/board/control");

      setDate(
        result.boardDate.Valid
          ? dayjs(result.boardDate.Time.split("T")[0])
          : dayjs()
      );
      setPage(result.page);
      return;
    } catch {
      setDate(dayjs());
      setPage(1);
      return;
    }
  };

  useEffect(() => {
    getDateAndPage();
  }, []);

  return (
    <DateContext.Provider
      value={{
        date,
        setDate: (newDate) => {
          setDate(newDate);
          setPage(1);
        },
        page,
        setPage,
      }}
    >
      {props.children}
    </DateContext.Provider>
  );
}
