import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatTime } from "../helpers/utils";
import { SubmitButton } from "../components/SubmitButton";

import { Grid, Typography, Box } from "@mui/material";
import { HttpClient } from "../helpers/httpClient";
import { useDataContext } from "../context/DataProvider";
import LimitedTextArea from "../components/LimitedTextArea";
import { useWebsocketContext } from "../context/WebsocketProvider";
import { publish, publishAndCall } from "../websockets/Websockets";

const dayString = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function LessonNote() {
  const navigate = useNavigate();
  const [loading2, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { boardData, horseData, loading, fetchData } = useDataContext();

  const [data, setData] = useState<{ [key: string]: string }>({});
  const { client, loadingWeb } = useWebsocketContext();

  async function handleNoteChange(notes: string, lessonId: string) {
    try {
      const result = await publishAndCall(
        client,
        {
          type: "lessonNoteChange",
          data: {
            lesson: lessonId,
            notes,
          },
        },
        {
          path: `/lessons/${lessonId}/notes`,
          body: {
            notes,
          },
        },
        true
      );

      console.log(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoading(false);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!boardData) {
    return <div>Error: Data not available</div>;
  }

  return (
    <div>
      <Grid item>
        <Typography variant="h3" gutterBottom align="center">
          Change Horse/Rider Note
        </Typography>
      </Grid>
      <Box padding={"2em"}>
        <Grid
          container
          item
          columnSpacing={2}
          paddingTop="0.5em"
          paddingBottom="0.5em"
          marginLeft="-0.5em"
        >
          <Grid item xs={3} sx={{ fontWeight: "bold" }}>
            Lesson
          </Grid>
          <Grid item xs={9} sx={{ fontWeight: "bold" }}>
            Note
          </Grid>
        </Grid>
        {boardData?.map((lessonTime, index) => (
          <Grid
            item
            container
            key={index}
            sx={{
              backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
            }}
            alignItems="center"
            justifyContent="center"
            columnSpacing={2}
            paddingTop="1em"
            paddingBottom="1em"
            marginLeft="-0.5em"
          >
            <Grid item xs={3}>
              {formatTime(lessonTime.lessonTime)}
            </Grid>
            <Grid item xs={9}>
              <LimitedTextArea
                label="Note"
                maxChars={250}
                data={
                  data[lessonTime.lessonId.toString()] !== undefined
                    ? data[lessonTime.lessonId.toString()]
                    : lessonTime.lessonNotes
                }
                setData={(event: ChangeEvent<HTMLInputElement>) => {
                  let newData = {
                    ...data,
                  };
                  newData[lessonTime.lessonId.toString()] = event.target.value;
                  setData(newData);
                }}
                onLeaveTextBox={() => {
                  if (data[lessonTime.lessonId.toString()] !== undefined) {
                    handleNoteChange(
                      data[lessonTime.lessonId.toString()],

                      lessonTime.lessonId.toString()
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
      <SubmitButton
        handleSubmit={async () => {
          navigate("/");
          await fetchData();
        }}
        disabled={loading2}
        label="Back"
      />
    </div>
  );
}

export default LessonNote;
