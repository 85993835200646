import { fetchAuthSession } from 'aws-amplify/auth';
import axios, { AxiosError, Method } from 'axios';

export class HttpClient {
  public static async get<T>(path: string, data?: object, queryParams?: object): Promise<T> {
    return this.makeRequest('get', path, data, queryParams);
  }

  public static async post<T>(path: string, data: object): Promise<T> {
    return this.makeRequest('post', path, data);
  }

  public static async put<T>(path: string, data: object): Promise<T> {
    return this.makeRequest('put', path, data);
  }

  public static async delete<T>(path: string, data?: object): Promise<T> {
    return this.makeRequest('delete', path, data);
  }

  private static async makeRequest<T>(method: Method, path: string, data?: object, queryParams?: object): Promise<T> {
    const session = await fetchAuthSession();

    console.log()
    const response = await axios.request({
      url: process.env.REACT_APP_APIGATEWAYURL + path,
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: session.tokens?.idToken?.toString(),
      },
      data,
      params: queryParams
    }).catch((error: AxiosError) => {
      throw error;
    });
    return response?.data;
  }
}
