import { Alert, Grid, Typography } from "@mui/material";

function ErrorBanner(props: { errorHeading?: string; errorMessage?: string }) {
  if (!props.errorMessage) return null;
  return (
    <Grid marginBottom={"2em"}>
      <Alert variant="filled" severity="error">
        <Typography variant="h6">{props.errorHeading}</Typography>
        <Typography>{props.errorMessage}</Typography>
      </Alert>
    </Grid>
  );
}

export default ErrorBanner;
