import { Outlet } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

export function Root() {
  const { route } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  if (route !== "authenticated") {
    return <Outlet />;
  }

  return (
    <>
      <div style={{ display: "flex", height: "100vh" }}>
        <div
          style={{
            flex: 1,
            overflowY: "auto",
          }}
        >
          <div
            style={{
              maxHeight: "100vh",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
